import React from "react"

import Seo from "components/seo"
import Layout from "components/layout"

import { Button } from "components/anti/buttons/buttons"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404" />
    <section className="py-main h-400px content-center">
      <div className="container mw-md text-center">
        <h1>Page not found</h1>
        <p>Sorry, the page you're looking for was not found.</p>
        <Button variant="primary" link="/">
          Go to Home Page
        </Button>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
